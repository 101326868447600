<template>
  <b-card
    no-body
    class="pa-0"
  >

    <general-table
      ref="damenPending"
      :api-url="apiUrl"
      :columns="columns"
      :type="type"
      :view-content="viewContent"
      :delete-content="false"
      :selectable="false"
      :search-box="false"
      :export-table="false"
      guard="merchant_types"
    >
      <template #cell(name)="data">
        {{ data.item.name_ar }}
      </template>
      <template #cell(image)="data">

        <img
          v-if="data.item.image"
          :src="url+data.item.image"
          width="80"
        >
        <span v-else>   <feather-icon
          icon="ImageIcon"
          size="18"
        />
        </span>
      </template>
      <template #cell(created_at)="data">
        {{ timeFormat(data.item.created_at) }}
      </template>
      <template #filterServices>
        <b-col md="3">
          <b-form-datepicker
            v-model="filter.from"
            placeholder="Start Date"
            reset-button
            close-button
            locale="en-GB"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-col>
        <b-col md="3">
          <b-form-datepicker
            v-model="filter.to"
            :min="filter.start_date"
            placeholder="End Date"
            reset-button
            close-button
            locale="en-GB"
            class="ml-1"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-col>
        <b-button
          class="mx-1"
          variant="primary"
          :disabled="Object.values(filter).length < 1"
          @click="$refs.damenPending.getAllData()"
        >
          Filter
        </b-button>

      </template>
      <template #cell(action)="data">
        <b-button
          size="sm"
          variant="primary"
          @click="refundUser(data.item.id)"
        >
          Refund
        </b-button>
      </template>
    </general-table>

  </b-card>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'serviceAggregators',
      type: 'modal',
      url: 'https://stage.zerocash.co/storage/',
      viewContent: false,
      loader: false,
      columns: [
        { key: 'id', sortable: true },
        { key: 'user', label: 'User' },
        { key: 'category', label: 'Category' },
        { key: 'service_type', label: 'Service Type' },
        { key: 'operator', label: 'Operator' },
        { key: 'created_at', label: 'Created At' },
        { key: 'amount', label: 'Amount' },
        { key: 'action' },

      ],
      filter: {},
      types: '',
      categories: [],
      billers: [],
    }
  },
  computed: {
    apiUrl() {
      let base = 'pendingTransactions?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },

  },
  mounted() {
    this.getCategories()
    this.getBillers()
  },
  methods: {
    timeFormat(date) {
      return moment(date).format(' DD MMM YYYY')
    },
    refundUser(id) {
      this.$swal({
        title: 'Are you sure you want to refund this user?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!',
      }).then(result => {
        if (result.isConfirmed) {
          axios.post(`transaction/${id}/refund`).then(() => {
            this.$swal(
              'Refunded!',
              'Refunded Successfully.',
              'success',
            )
            this.$refs.damenPending.getAllData()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    filterTypes(type) {
      const filteredType = this.categories.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>

<style>

</style>
